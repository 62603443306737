import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';

import { HoverButton } from 'components/CustomButton';
import { HeroImageTemplate,B2CImageTemplate } from 'components/CustomImageTemplate';
import Chip from 'components/Chip';

const useStyles = makeStyles((theme) => ({
  heroSectionContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.spacing(28.5),
    marginBottom: theme.spacing(28.75),
    transition: 'transform 0.4s ease-in-out, opacity 0.5s ease-in-out',
    transform: 'translateY(50px)',
    opacity: 0,
    padding: theme.spacing(0, 37.5),
    [theme.breakpoints.down("xl")]: {
      marginTop: theme.spacing(15),
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(10),
      padding: theme.spacing(0, 15),
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(7),
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 5),
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  contentElements: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(6),
    }
  },
  textElements: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    maxWidth: '753px',
    [theme.breakpoints.down("xl")]: {
      maxWidth: '657px',
    },
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(4),
    }
  },
  buttonElements: {
    display: 'flex',
    gap: theme.spacing(5),
    flexWrap:"wrap",
    [theme.breakpoints.down("440")]: {
      gap: theme.spacing(3),
      '& button': {
      width: '100%',
    },
    },
  },
  head: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    [theme.breakpoints.down("440")]: {
      width: '100%',
    }
  },
  textDecoration:{
    textDecoration:"none"
  },
  hover:{
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
    alignItems: "center",
    background:`linear-gradient(278.27deg,#4B6CB7 0%,#002759 112.69%),
                linear-gradient(0deg,#FFFFFF,#FFFFFF)`,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2.5, 6, 2.5, 6),
    borderRadius: '8px',
    textTransform: 'none',
    overflow:"hidden",
    [theme.breakpoints.down('440px')]:{
      width:"100%"
    },
    "&::before":{
       content:`""`,
       position:"absolute",
       top:0,
       left:-20,
       height:"50px",
       width:19,
       opacity:0.25,
       transform: "skew(-45deg)",
       background:`white`,
       animation:'$load 2.2s cubic-bezier(0.45, 1.45, 0.8, 1) infinite',
    },
    "&:hover": {
      boxShadow: "0px 2px 8px 1px #003E8CCC",
      [theme.breakpoints.down('md')]:{
        boxShadow:"none"
      }
    },
  },
  '@keyframes load': {
    '0%': {
     transform: "translate3d(-30px, 0, 0) skew(-45deg)",
   },
   '13.64%': { 
     transform: "translate3d(420px, 0, 0) skew(-45deg)",
   },
   '50%': {
     transform: "translate3d(420px, 0, 0) skew(-45deg)",
   },
   '63.64%': {
     transform: "translate3d(-30px, 0, 0) skew(-45deg)",
   },
   '100%': {
     transform: "translate3d(-30px, 0, 0) skew(-45deg)",
   }
 },
 iconContainer:{
    cursor: "pointer",
    border: `2px solid`,
    borderColor: theme.palette.primary['clr-500'],
    borderRadius: "8px",
    height: "48px",
    backgroundColor: theme.palette.shades['clr-white-900'],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    left: 0,
    width:169,
    textTransform:"none",
    padding: theme.spacing(0),
    color:theme.palette.primary.main,
    gap:theme.spacing(1),
    "&:hover": {
      boxShadow: "0px 2px 6px 2px #0000004D",
      [theme.breakpoints.down('md')]:{
        boxShadow:"none"
      }
    },
  },
}));

const HeroSection = ({ title, img, subTitle="Conversational AI for Interview preparation" , 
  hoverBtnText="Try our AI for free",onExperienceLive,B2C=false }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 100);
  }, []);

  return (
    <Box
      className={classes.heroSectionContainer}
      style={{
        transform: loaded ? 'translateY(0)' : 'translateY(50px)',
        opacity: loaded ? 1 : 0
      }}
    >
      <Box className={classes.contentContainer}>
        <Chip
          content="AI Powered"
          startIcon={<img src="https://assets.languify.in/images/Magic.svg" alt="Magic Icon" />}
          color={theme.palette.tertiary['clr-700']}
          bgColor={theme.palette.tertiary['clr-50']}
          sx={{
            borderRadius: '12px',
            display:"flex",
            alignItems:"center"
          }}
        />
        <Box className={classes.contentElements}>
          <Box className={classes.textElements}>
            {title}
            <Typography variant="h5-medium" color="neutral.main">
             {subTitle}
            </Typography>
          </Box>
          <Box className={classes.buttonElements}>
            <HoverButton 
              label={hoverBtnText} 
              onClick={onExperienceLive}
              icon="https://assets.languify.in/images/Stars.svg"
              className={classes.hover}
             />

            {!B2C && <Box className={classes.iconButton}>
              <a
                target='_blank' 
                rel='noreferrer' 
                href={process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL}
                className={classes.textDecoration} >
                  <Button
                    className={`${classes.iconContainer}`}
                  >
                    <Typography variant='h6-medium' className={classes.hoveext}>
                      Book a call
                    </Typography>
                    <img 
                      src="https://assets.languify.in/images/messageChat.svg" 
                      width={24} alt='book' 
                      className={classes.hoverImage} />
                  </Button>
              </a>
            </Box>}
          </Box>
        </Box>
        <Chip
          content="9000+ students in India, Europe & Africa"
          startIcon={<img src="https://assets.languify.in/images/Medal.svg" alt="Medal Icon" />}
          color={theme.palette.success['clr-700']}
          bgColor={theme.palette.success['clr-100']}
          sx={{
            borderRadius: '12px'
          }}
        />
      </Box>
      {!B2C ?<HeroImageTemplate img={img} />:<B2CImageTemplate img={img}/>}
    </Box>
  );
};

export default HeroSection;
